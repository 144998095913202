// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-come-affittare-casa-tsx": () => import("./../../../src/pages/come-affittare-casa.tsx" /* webpackChunkName: "component---src-pages-come-affittare-casa-tsx" */),
  "component---src-pages-confirm-opt-in-tsx": () => import("./../../../src/pages/confirm-opt-in.tsx" /* webpackChunkName: "component---src-pages-confirm-opt-in-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-phone-ok-tsx": () => import("./../../../src/pages/phone-ok.tsx" /* webpackChunkName: "component---src-pages-phone-ok-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-protezione-zappyrent-tsx": () => import("./../../../src/pages/protezione-zappyrent.tsx" /* webpackChunkName: "component---src-pages-protezione-zappyrent-tsx" */),
  "component---src-pages-pubblica-annuncio-gratis-tsx": () => import("./../../../src/pages/pubblica-annuncio-gratis.tsx" /* webpackChunkName: "component---src-pages-pubblica-annuncio-gratis-tsx" */),
  "component---src-pages-refer-a-landlord-tsx": () => import("./../../../src/pages/refer-a-landlord.tsx" /* webpackChunkName: "component---src-pages-refer-a-landlord-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

