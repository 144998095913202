/* eslint-disable */
export const shouldUpdateScroll = ({
  routerProps: {
    location: { hash },
  },
}) => {
  if (hash) {
    document.querySelector(hash).scrollIntoView();
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }

  return false;
};
